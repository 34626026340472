<template>
  <div v-loading.fullscreen.lock="loading" class="elv-ledger-account-balance-detail-page">
    <Header
      ref="headerRef"
      type="GENERAL_LEDGER"
      :title="t('menus.ledger')"
      :updated-on="entityDetail?.progress.postingDoneTime ?? ''"
      :show-regenerate-button="false"
      :show-regenerate="false"
    />

    <HeaderTabs />

    <AccountBalanceScreening
      ref="screeningRef"
      v-model:group="groupParams.group"
      v-model:subGroup="groupParams.subGroup"
      table-type="accountBalance"
      :defaultTime="defaultTime"
      :exportParams="exportParams"
      :defaultListType="defaultListType"
      :groupOptions="groupOptions"
      :subGroupOptions="subGroupOptions"
      @onChangeTime="onChangeTime"
      @onChangeGroup="onChangeGroup"
      @onCheckTableExpand="onCheckTableExpand"
    />
    <ExportDataTaskHistoryInfo :exportType="exportProgressStatusType.accountBalance" />
    <ProcessTable
      ref="processTableRef"
      type="account-balance"
      :height="tableHeight"
      :dateTimeValue="dateTimeValue"
      :summaryParams="summaryParams"
    />
  </div>
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'lodash-es'
import { emitter } from '@/lib/mitt'
import LedgerApi from '@/api/LedgerApi'
import Header from '../../components/Header.vue'
import HeaderTabs from '../components/HeaderTabs.vue'
import { useEntityStore } from '@/stores/modules/entity'
import ProcessTable from '../components/ProcessTable.vue'
import { exportProgressStatusType } from '@/config/global'
import { useLedgerStore } from '@/stores/modules/ledger/index'
import AccountBalanceScreening from '../components/LedgerScreening.vue'
import { useLedgerTableConfigStore } from '@/stores/modules/ledger/tableConfig'
import ExportDataTaskHistoryInfo from '../../components/ExportDataTaskHistoryInfo.vue'

dayjs.extend(utc)
const { t } = useI18n()
const route = useRoute()
const ledgerStore = useLedgerStore()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)
const tableConfigStore = useLedgerTableConfigStore()
const loading = ref(false)
const processTableRef = useTemplateRef('processTableRef')
const headerRef = useTemplateRef<InstanceType<typeof Header>>('headerRef')
const screeningRef = useTemplateRef<InstanceType<typeof AccountBalanceScreening>>('screeningRef')
const groupParams = ref({
  group: 'CHART_OF_ACCOUNT',
  subGroup: 'NONE'
})
const defaultListType = ref('EXPAND')
const defaultTime = ref<string[]>(['', ''])

const auxiliaryCodeTypeData: any = ref({
  total: 0,
  list: []
})

const entityId = computed(() => {
  return String(route.params.entityId)
})

const dateTimeValue = computed(() => {
  return screeningRef.value?.dateTimeValue ?? []
})

const groupOptions = computed(() => {
  const options: any = []
  if (groupParams.value.subGroup !== 'CHART_OF_ACCOUNT') {
    options.push({
      label: 'Account',
      value: 'CHART_OF_ACCOUNT'
    })
  }
  if (auxiliaryCodeTypeData.value.list.length > 0) {
    options.push({
      label: 'Auxiliary Code',
      options: auxiliaryCodeTypeData.value.list.map((item: any) => {
        return {
          label: item.type === 'ENTITY_ACCOUNT' ? 'Treasury account' : item.name,
          value: item.auxiliaryTypeId,
          ...item
        }
      })
    })
  }
  console.log(options)
  return options
})

const subGroupOptions = computed(() => {
  const options: any = []
  if (groupParams.value.group !== 'CHART_OF_ACCOUNT') {
    options.push({
      label: 'Account',
      value: 'CHART_OF_ACCOUNT'
    })
  }
  if (auxiliaryCodeTypeData.value.list.length > 0) {
    options.push({
      label: 'Auxiliary Code',
      options: auxiliaryCodeTypeData.value.list.map((item: any) => {
        return {
          label: item.type === 'ENTITY_ACCOUNT' ? 'Treasury account' : item.name,
          value: item.auxiliaryTypeId,
          ...item
        }
      })
    })
  }
  options.push({
    label: 'None',
    value: 'NONE'
  })
  return options
})

const summaryParams = computed(() => {
  const params: any = {
    periodBegin: dateTimeValue.value?.[0],
    periodEnd: dateTimeValue.value?.[1],
    group: groupParams.value.group,
    subGroup: groupParams.value.subGroup
  }
  if (groupParams.value.group !== 'CHART_OF_ACCOUNT') {
    const auxiliaryType = auxiliaryCodeTypeData.value.list.find(
      (item: any) => item.auxiliaryTypeId === groupParams.value.group
    )
    params.auxiliaryTypeId = auxiliaryType?.auxiliaryTypeId
    params.group = auxiliaryType?.type === 'ITEM' ? 'AUXILIARY_ITEM' : (auxiliaryType?.type ?? 'CHART_OF_ACCOUNT')
  }
  if (!['CHART_OF_ACCOUNT', 'NONE'].includes(groupParams.value.subGroup)) {
    const auxiliaryType = auxiliaryCodeTypeData.value.list.find(
      (item: any) => item.auxiliaryTypeId === groupParams.value.subGroup
    )
    params.auxiliaryTypeId = auxiliaryType?.auxiliaryTypeId
    params.subGroup = auxiliaryType?.type === 'ITEM' ? 'AUXILIARY_ITEM' : (auxiliaryType?.type ?? 'NONE')
  }
  return params
})

const exportParams = computed(() => {
  return {
    type: exportProgressStatusType.accountBalance.value,
    extra: summaryParams.value
  }
})

const tableHeight = computed(() => {
  return headerRef.value?.isShowRegeneratedInfo ? `calc(100vh - 168px)` : `calc(100vh - 192px)`
})

const onChangeTime = () => {
  processTableRef.value?.resetList()
}

const onCheckTableExpand = (status: string) => {
  defaultListType.value = status
  if (status === 'EXPAND') {
    ledgerStore.agGridApi?.expandAll()
  } else {
    ledgerStore.agGridApi?.collapseAll()
  }
}

const onChangeGroup = async (type: string) => {
  if (
    type === 'GROUP' &&
    groupParams.value.group !== 'CHART_OF_ACCOUNT' &&
    groupParams.value.subGroup !== 'CHART_OF_ACCOUNT'
  ) {
    groupParams.value.subGroup = 'CHART_OF_ACCOUNT'
  } else if (
    type === 'SUB_GROUP' &&
    groupParams.value.group !== 'CHART_OF_ACCOUNT' &&
    groupParams.value.subGroup !== 'CHART_OF_ACCOUNT'
  ) {
    groupParams.value.group = 'CHART_OF_ACCOUNT'
  }
  ledgerStore.editAccountBalanceGroupFilter(entityId.value, groupParams.value)
  await tableConfigStore.getProcessTableConfig('accountBalance', [], 'left', groupParams.value.subGroup !== 'NONE')
  // emitter.emit('resetList')
  processTableRef.value?.resetList()
}

const getAuxiliaryTypeList = async () => {
  try {
    loading.value = true
    const { data } = await LedgerApi.getAuxiliaryTypeList(entityId.value, {
      page: 1,
      limit: 100
    })
    auxiliaryCodeTypeData.value = {
      total: data?.total ?? 0,
      list: data?.list ?? []
    }
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}

watch(
  [() => route, () => entityStore.entityDetail],
  async (newVal, oldVal) => {
    if (
      route.name === 'entity-ledger-account-balance' &&
      (entityStore.entityDetail?.creationDate || entityStore.entityDetail?.createdAt) &&
      newVal[1].websocketUrl &&
      newVal[1].websocketUrl !== oldVal?.[1]?.websocketUrl
    ) {
      loading.value = true

      const start = entityStore.entityDetail?.creationDate
        ? dayjs.tz(entityStore.entityDetail?.creationDate, entityStore.entityDetail?.timezone).format('YYYY-MM-DD')
        : dayjs.tz(entityStore.entityDetail?.createdAt, entityStore.entityDetail?.timezone).format('YYYY-MM-DD')
      const end = entityStore.entityDetail?.progress?.postingDoneTime
        ? dayjs
            .tz(entityStore.entityDetail?.progress?.postingDoneTime, entityStore.entityDetail?.timezone)
            .subtract(1, 'day')
            .format('YYYY-MM-DD')
        : dayjs().tz(entityStore.entityDetail?.timezone).subtract(1, 'day').format('YYYY-MM-DD')
      defaultTime.value = [start, end]
      tableConfigStore.processTableConfig = []
      await getAuxiliaryTypeList()
      const accountBalanceGroup = ledgerStore.accountBalanceGroupFilter.find((item: any) => {
        return item.entityId === entityId.value
      })
      if (!isEmpty(accountBalanceGroup)) {
        groupParams.value.group = accountBalanceGroup.data?.group ?? 'CHART_OF_ACCOUNT'
        groupParams.value.subGroup = accountBalanceGroup.data?.subGroup ?? 'NONE'
        if (
          groupParams.value.group !== 'CHART_OF_ACCOUNT' &&
          isEmpty(
            auxiliaryCodeTypeData.value.list.find((item: any) => item.auxiliaryTypeId === groupParams.value.group)
          )
        ) {
          groupParams.value.group = 'CHART_OF_ACCOUNT'
          groupParams.value.subGroup = 'NONE'
        }
        if (
          !['CHART_OF_ACCOUNT', 'NONE'].includes(groupParams.value.subGroup) &&
          isEmpty(
            auxiliaryCodeTypeData.value.list.find((item: any) => item.auxiliaryTypeId === groupParams.value.subGroup)
          )
        ) {
          groupParams.value.group = 'CHART_OF_ACCOUNT'
          groupParams.value.subGroup = 'NONE'
        }
      }
      await tableConfigStore.getProcessTableConfig('accountBalance', [], 'left', groupParams.value.subGroup !== 'NONE')
      emitter.emit('resetList')
    }
    loading.value = false
  },
  { immediate: true, deep: true }
)
</script>
<style lang="scss" scoped>
.elv-ledger-account-balance-detail-page {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
</style>
